import { Component, Inject, Injector, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomValidators, DialogContent, FormGroupDefinition } from 'components';
import { compare } from 'fast-json-patch';
import { Observable, of } from 'rxjs';
import { first, map, take } from 'rxjs/operators';
import { NotificationService } from 'src/app/core/services/notification.service';
import { FinancialClassWrapperService } from 'src/app/core/services/service-wrappers/financial-class-wrapper.service';
import { AdjustmentCodesLookupService } from 'src/app/core/services/lookup/adjustment-codes-lookup.service';
import { PaymentCodesLookupService } from 'src/app/core/services/lookup/payment-codes-lookup.service';
import { StatementMessageLookupService } from '../../../../core/services/lookup/statement-message-lookup.service';
import { DialogService } from '@core/services/dialog.service';

@Component({
  selector: 'app-financial-class-dialog',
  templateUrl: './financial-class-dialog.component.html',
  styleUrls: ['./financial-class-dialog.component.scss']
})
export class FinancialClassDialogComponent implements OnInit {
  formGroup = new UntypedFormGroup({});
  formInitialized = false;
  saving = false;
  financialClassId;
  viewOnly = false;

  formDefinitions: FormGroupDefinition[];
  getFormDefinitions(isEdit: boolean): FormGroupDefinition[] {
    return [
      {
        hideTitle: true,
        controls: [
          {
            label: 'Financial Class Code',
            name: 'code',
            isReadOnly: isEdit,
            type: 'text',
            class: 'form-span-4',
            validators: Validators.required
          },
          {
            label: 'Financial Class Name',
            name: 'name',
            type: 'text',
            class: 'form-span-8',
            validators: Validators.required
          },
          {
            label: 'Hold Billing',
            name: 'holdBilling',
            type: 'checkbox',
            initial: true,
            class: 'form-span-2'
          },
          {
            label: 'Active',
            name: 'active',
            type: 'checkbox',
            initial: true,
            class: 'form-span-2',
            validators: Validators.required
          },
          {
            label: 'Workers Comp',
            name: 'workersComp',
            type: 'select',
            options: [
              {
                label: 'Yes',
                value: true
              },
              {
                label: 'No',
                value: false
              }
            ],
            class: 'form-span-4'
          },
          {
            label: 'No Response Visit Queue',
            name: 'sendToDelinquent',
            type: 'text',
            class: 'form-span-4',
            validators: [CustomValidators.numberValidator]
          },
          {
            label: 'Default Adjustment Code',
            name: 'defaultAdjustmentCode',
            type: 'select',
            apiService: this.adjustmentCodesLookupService,
            class: 'form-span-4',
            clearButton: true
          },
          {
            label: 'Default Adjustment Code 2',
            name: 'defaultAdjustmentCode2',
            type: 'select',
            apiService: this.adjustmentCodesLookupService,
            class: 'form-span-4',
            clearButton: true
          },
          {
            label: 'Default Payment Code',
            name: 'defaultPaymentCode',
            type: 'select',
            apiService: this.paymentCodesLookupService,
            class: 'form-span-4',
            clearButton: true
          },
          {
            label: 'Default Interest Code',
            name: 'defaultInterestCode',
            type: 'select',
            apiService: this.adjustmentCodesLookupService,
            class: 'form-span-4',
            clearButton: true
          },
          {
            name: 'sendStatement',
            label: 'Send Statement',
            type: 'radio',
            options: [
              {
                label: 'Yes',
                value: true
              },
              {
                label: 'No',
                value: false
              }
            ],
            initial: true,
            class: 'form-span-4'
          },
          {
            label: 'Statement Cycle',
            name: 'statementCycle',
            type: 'text',
            class: 'form-span-4',
            validators: [CustomValidators.numberValidator, Validators.required]
          },
          {
            label: 'Statement Minimum',
            name: 'statementMinimum',
            type: 'text',
            class: 'form-span-4',
            validators: [CustomValidators.moneyFormatValidator]
          },
          {
            label: 'Automate Collections',
            name: 'automateCollections',
            type: 'checkbox',
            initial: false,
            class: 'form-span-12'
          },
          {
            label: 'Delinquency Code',
            name: 'collectionsDelinquency',
            type: 'text',
            class: 'form-span-4',
            validators: [CustomValidators.numberValidator]
          },
          {
            label: 'Days Since Payment',
            name: 'collectionsPaymentDays',
            type: 'text',
            class: 'form-span-4',
            validators: [CustomValidators.numberValidator]
          },
          {
            label: 'Days Since Statement',
            name: 'collectionsStatementDays',
            type: 'text',
            class: 'form-span-4',
            validators: [CustomValidators.numberValidator]
          },
          {
            label: 'Automate WriteOff',
            name: 'automateWriteoff',
            type: 'checkbox',
            initial: false,
            class: 'form-span-12'
          },
          {
            label: 'Days In Collections Queue',
            name: 'writeoffDays',
            type: 'text',
            class: 'form-span-6',
            validators: [CustomValidators.numberValidator]
          },
          {
            label: 'Minimum Balance',
            name: 'writeoffMinimum',
            type: 'text',
            class: 'form-span-6',
            validators: [CustomValidators.moneyFormatValidator]
          },
        ]
      },
    ];
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private injector: Injector,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<FinancialClassDialogComponent>,
    private service: FinancialClassWrapperService,
    private adjustmentCodesLookupService: AdjustmentCodesLookupService,
    private paymentCodesLookupService: PaymentCodesLookupService,
    private statementMessageLookup: StatementMessageLookupService,
    private dialogService: DialogService
  ) {
    this.financialClassId = data?.financialClassId;
    const isEdit = this.financialClassId && this.financialClassId !== '';
    this.viewOnly = data?.viewOnly;
    this.formDefinitions = this.getFormDefinitions(isEdit);
  }

  ngOnInit(): void {
    this.setupForm();

    if (this.financialClassId && this.financialClassId !== '') {
      this.service
        .apiV1FinancialClassIdGet(this.financialClassId)
        .pipe(first())
        .subscribe((result) => {
          this.paymentCodesLookupService.existingValues.push(result.paymentCodeId);
          this.adjustmentCodesLookupService.existingValues.push(result.adjustmentCodeId);
          this.adjustmentCodesLookupService.existingValues.push(result.adjustmentCode2Id);
          this.formGroup.patchValue(result);
          this.formGroup.get('sendToDelinquent').patchValue(result.dlqDays);
          this.formGroup.get('defaultPaymentCode').patchValue(result.paymentCodeId);
          this.formGroup.get('defaultInterestCode').patchValue(result.interestCodeId);
          this.formGroup.get('defaultAdjustmentCode').patchValue(result.adjustmentCodeId);
          this.formGroup.get('defaultAdjustmentCode2').patchValue(result.adjustmentCode2Id);
          this.formGroup.get('active').patchValue(!result.inactive);
          this.formGroup.get('sendStatement').patchValue(result.sendStatement);
        });
    }
  }

  setupForm() {
    this.formDefinitions.forEach((sc) => {
      sc.controls.forEach((control) => {
        if (control.type !== 'empty' && control.type !== 'label') {
          this.formGroup.addControl(control.name, new UntypedFormControl(control.initial ?? '', control.validators));
        }
      });
    });
    this.formInitialized = true;
  }

  save() {
    if (this.formGroup.valid && this.saving === false) {
      this.saving = true;

      const formData = {
        code: this.formGroup.get('code').value,
        name: this.formGroup.get('name').value,
        inactive: this.formGroup.get('active').value === true ? false : true,
        holdBilling: this.formGroup.get('holdBilling').value,
        workersComp: this.formGroup.get('workersComp').value ? this.formGroup.get('workersComp').value : "false",
        sendStatement: this.formGroup.get('sendStatement').value,
        statementMinimum: this.formGroup.get('statementMinimum').value ? this.formGroup.get('statementMinimum').value : "0",
        statementCycle: this.formGroup.get('statementCycle').value,
        paymentCodeId: this.formGroup.get('defaultPaymentCode').value,
        interestCodeId: this.formGroup.get('defaultInterestCode').value,
        adjustmentCodeId: this.formGroup.get('defaultAdjustmentCode').value,
        adjustmentCode2Id: this.formGroup.get('defaultAdjustmentCode2').value,
        dlqDays: this.formGroup.get('sendToDelinquent').value ? this.formGroup.get('sendToDelinquent').value : "0",
        writeoffDays: this.formGroup.get('writeoffDays').value,
        writeoffMinimum: this.formGroup.get('writeoffMinimum').value != '' ? this.formGroup.get('writeoffMinimum').value : 0.00,
        collectionsPaymentDays: this.formGroup.get('collectionsPaymentDays').value,
        collectionsStatementDays: this.formGroup.get('collectionsStatementDays').value,
        collectionsDelinquency: this.formGroup.get('collectionsDelinquency').value,
        automateCollections: this.formGroup.get('automateCollections').value,
        automateWriteoff: this.formGroup.get('automateWriteoff').value,
      };

      let saveObservable: Observable<any>;
      let notification;
      if (formData.automateCollections === true && (formData.collectionsDelinquency === null && formData.collectionsPaymentDays === null && formData.collectionsStatementDays === null)) {
        const modalContent: DialogContent = {
          header: 'Required Fields',
          body: "At least one criteria to automate collections is required when 'Automate Collections' is selected.",
          OKButtonText: 'OK',
          cancelButtonVisible: false
        };
        this.dialogService.showConfirm(modalContent);
        this.saving = false;
        return;
      } else if (formData.automateWriteoff === true && (formData.writeoffDays === null && formData.writeoffMinimum === 0)) {
          const modalContent: DialogContent = {
            header: 'Required Fields',
            body: "At least one criteria to automate writeoffs is required when 'Automate Writeoffs' is selected.",
            OKButtonText: 'OK',
            cancelButtonVisible: false
          };
          this.dialogService.showConfirm(modalContent);
          this.saving = false;
          return;
      } else if (this.financialClassId) {
        notification = 'Financial Class Updated';
        saveObservable = this.service
          .apiV1FinancialClassPatchIdPatch(this.financialClassId, compare({}, formData))
          .pipe(
            map((x: any) => {
              x;
            })
          );
      } else {
        notification = 'Financial Class Added';
        saveObservable = this.service.apiV1FinancialClassAddPost(formData).pipe(map((x: any) => x));
      }

      saveObservable
        .pipe(take(1))
        .subscribe(
          (response) => {
            this.dialogRef.close(true);
            this.notificationService.success(notification);
          },
          (err) => this.notificationService.error('Saving Financial Class')
        )
        .add(() => {
          this.saving = false;
        });
    }
  }
}
